// import firebase from "firebase";
// const firebaseApp = firebase.initializeApp(firebaseConfig);
// const db = firebase.firestore();
// export default db;

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration

// jitendra 
// const firebaseConfig = {
//   apiKey: "AIzaSyDPomCjlFKQjgqX_zzLViFx_ULJTuhDt8Q",
//   authDomain: "spachat-9ba12.firebaseapp.com",
//   projectId: "spachat-9ba12",
//   storageBucket: "spachat-9ba12.appspot.com",
//   messagingSenderId: "167756063621",
//   appId: "1:167756063621:web:5cc4d43394a770fa739aec"
// };

// piyush 
// const firebaseConfig = {
//   apiKey: "AIzaSyDHQwbvNuTud607fB_wM7uMkQtw484-z-0",
//   authDomain: "spa-chat-5199e.firebaseapp.com",
//   projectId: "spa-chat-5199e",
//   storageBucket: "spa-chat-5199e.appspot.com",
//   messagingSenderId: "1006168981733",
//   appId: "1:1006168981733:web:a6aac069f04599ae93940c"
// };

// adil 

// const firebaseConfig = {
//   apiKey: "AIzaSyBZ1-kZoqNcr72qSnIg7wpH9ZHo8uxEssg",
//   authDomain: "spasalon-43e2b.firebaseapp.com",
//   projectId: "spasalon-43e2b",
//   storageBucket: "spasalon-43e2b.appspot.com",
//   messagingSenderId: "808970198544",
//   appId: "1:808970198544:web:fd0b3da61479d226cb7495",
//   measurementId: "G-Q6C9GD4EGW"
// };

// pathan 
const firebaseConfig = {
  apiKey: "AIzaSyDhLI3arh-1oC60j3rh8cgMFrqJong2f38",
  authDomain: "wellnezza.firebaseapp.com",
  projectId: "wellnezza",
  storageBucket: "wellnezza.firebasestorage.app",
  messagingSenderId: "380157924623",
  appId: "1:380157924623:web:a6810abb5df897ea12da7a",
  measurementId: "G-QJ4TDYYFBP"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
