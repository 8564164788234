import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import st from "../../style.module.scss";
import cx from "./index.module.scss";
import { FaTelegramPlane } from "react-icons/fa";
import { Badge, Col, Dropdown, Row } from "react-bootstrap";
import { FiCamera, FiSearch } from "react-icons/fi";
import { GrAttachment } from "react-icons/gr";
import { FaUserPlus } from "react-icons/fa";
import { MdCall, MdKeyboardVoice, MdOutlineMoreVert } from "react-icons/md";
import User from "./User";
import { db } from './firebase'
import { doc, setDoc, getDocs, getDoc, onSnapshot, collection, updateDoc } from "firebase/firestore";
import Chats from "./Chats";
import moment from 'moment';
import { httpRequest } from "../../Apis/commonApis";

const ChatBox = (props: any) => {
    const [businessData, setBusinessData] = useState<any>([]);
    const [selectedChat, setSelectedChat] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false)

    const [chatHeadList, setChatHeadList] = useState(null);
    const [clientChatHeadList, setClientChatHeadList] = useState(null);
    const [filterMessaeg, setFilterMessaeg] = useState("")
    const [activeUsers, setActiveUsers] = useState<any[]>([]);
    const [chats, setChats] = useState<any>([]);

    const firstNameLetter = selectedChat?.businessName?.[0]?.toUpperCase();
    const firstLastNameLetter = selectedChat?.businessName?.[1]?.toUpperCase();

    function handleChatSelection(chat: any) {
        if (chat) {
            setSelectedChat(chat)
        }
    }
    const isActive = activeUsers.includes(selectedChat?.clientId);


    async function changeOnlineStatusTrue() {
        try {
            const res = await getDoc(doc(db, "superadminonlineStatus", 'SA'));
            if (!res.exists()) {
                await setDoc(doc(db, "superadminonlineStatus", 'SA'), {
                    isOnline: true,
                    time: Date.now()
                });
            } else {
                await updateDoc(doc(db, "superadminonlineStatus", 'SA'), {
                    isOnline: true,
                    time: Date.now()
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
    async function changeOnlineStatusFalse() {
        await updateDoc(doc(db, "superadminonlineStatus", 'SA'), {
            isOnline: false,
            time: Date.now()
        });
    }

    useEffect(() => {
        changeOnlineStatusTrue()

        return () => {
            changeOnlineStatusFalse()
        }
    }, [])

    useEffect(() => {
        getBusinessInfo()
    }, [])

    const getBusinessInfo = async () => {
        setLoading(true)
        const res = await httpRequest(`get_BusinessInfo`, "get", null, "withoutToken");
        if (res?.status) {
            setBusinessData(res?.data)
        };
        setLoading(false)
    }

    useEffect(() => {
        const unSub = onSnapshot(
            collection(db, "superadminuser"),
            (querySnapshot) => {
                let headdata: any = [];
                querySnapshot.forEach((doc) => {
                    headdata.push(doc.data())
                });
                setChats(headdata)
            }
        );
        return () => {
            unSub();
        };
    }, []);

    const filterBusiness = businessData?.map((item: any) => {
        // Find the corresponding business in the chats
        const chattedBusiness = chats.find((cb: any) =>
            (cb?.businessId === item?._id) ||
            (cb?.businessId === item?._id?.replaceAll('-', ''))
        );
        return { ...item, ...chattedBusiness };
    })?.sort((a: any, b: any) => {
        const aUpdate = a?.updateMessage ?? 0;
        const bUpdate = b?.updateMessage ?? 0;
        return bUpdate - aUpdate;
    });
    
    const selectedBusiness = selectedChat ? chats?.find((item: any) => (item?.businessId === selectedChat?._id) || (item?.businessId === selectedChat?._id?.replaceAll("-", ''))) : null
    return (
        <>
            <section className={`${st.pageWrapper} pb-0 pe-0 ps-1`} style={{ paddingTop: '59px !important' }}>
                <Row className="m-0">
                    <User
                        handleChatSelection={handleChatSelection}
                        selectedChat={selectedChat}
                        loading={loading}
                        businessData={filterBusiness}
                        chats={chats}
                    />
                    {selectedChat && <Col lg={5} className={`${cx.detailBox} ${cx.chatBoxHide} p-0 `}>
                        <div className={`${cx.chatbody}`}>
                            <Row className={`${cx.chatHead}`}>
                                <Col md={10}>
                                    <div className={`${cx.userDetails}`}>
                                        {(selectedChat?.profileImage || selectedChat?.image?.[0]) ? (
                                            <img src={selectedChat?.profileImage || selectedChat?.image?.[0] || ''} className={`${cx.profileImg}`} alt={selectedChat?.businessName} />
                                        ) : (
                                            <div className={cx.emptyImageBox}>
                                                <span>{selectedChat?.businessName?.[0]?.toLocaleUpperCase()}{selectedChat?.businessName?.[1]?.toLocaleUpperCase()}</span>
                                            </div>
                                        )}
                                        <div className={`${cx.chatListBody}`}>
                                            <h5>{`${selectedChat?.businessName}`}</h5>
                                            <p className={`${cx.status}`}>
                                                {selectedBusiness?.isOnline ? (
                                                    <span> Online</span>
                                                ) : (
                                                    <span>Offline</span>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <ul className={`${cx.rightTopList}`}>

                                    </ul>
                                </Col>
                            </Row>
                            {selectedChat?._id && <Chats selectedChat={selectedChat}
                                currentUserId={selectedChat?._id}
                                chatHeadList={chatHeadList}
                                filterMessaeg={filterMessaeg}
                                clientChatHeadList={clientChatHeadList}
                                loading={loading}
                            />}

                        </div>
                    </Col>}
                    {selectedChat?._id && <Col className={`${cx.detailBox} col p-0 `}>
                        <div className={`${cx.detailBody}`}>
                            <div className={`${cx.heading}`}>
                                <h5>Details</h5>
                            </div>
                            <div className={`${cx.connection}`}>
                                <div className={`${cx.connectionBox}`}>
                                    {selectedChat?.image?.[0] || selectedChat?.profileImage ? <div className={`${cx.imageBox}`}>
                                        <img src={selectedChat?.profileImage || selectedChat?.image?.[0] || ''} className={`${cx.connectImage}`} />
                                    </div> : (
                                        <div className={`${cx.imageLogoBox}`}>
                                            <span>{firstNameLetter}{firstLastNameLetter}</span>
                                        </div>
                                    )}
                                    <h3>{selectedChat?.businessName} </h3>
                                    {/* <div className={`${cx.callBox}`} >
                                        <img src={''} alt="call-icon" onClick={handleContactDetailsShow} />
                                    </div> */}
                                </div>
                            </div>
                            <div className={`${cx.dateHeading}`}>
                                <h5>Details</h5>
                            </div>
                            <div className={`${cx.appointment}`}>
                                <h2>{selectedChat?.city || ''} {selectedChat?.state || ''} {selectedChat?.country || ''}</h2>
                                {/* {selectedChat?.createdAt && <p>{new Date(selectedChat?.createdAt)?.toLocaleString()}</p>} */}
                                {selectedChat?.isPublished === "published" && <div className={`${cx.services}`}>
                                    <span>{selectedChat?.isPublished}</span>
                                </div>}

                            </div>
                        </div>
                        {/* <div className={`${cx.price}`}>
                            <span>Price</span>
                            <span className={`${cx.amount}`}>{selectedChat?.finalPrice}USD</span>
                        </div> */}
                    </Col>}
                </Row>
            </section>
            {/* </Col> */}

        </>


    );
};

export default ChatBox;
