// Helper function to get the date label (e.g., Today, Yesterday, or a specific date)
export function getDateLabel(timestamp:any) {
    const messageDate = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    const options:any = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedMessageDate = messageDate.toLocaleDateString(undefined, options);
    const formattedToday = today.toLocaleDateString(undefined, options);
    const formattedYesterday = yesterday.toLocaleDateString(undefined, options);
  
    if (formattedMessageDate === formattedToday) {
      return 'Today';
    } else if (formattedMessageDate === formattedYesterday) {
      return 'Yesterday';
    } else {
      return formattedMessageDate; // Return formatted date (e.g., Nov 27, 2024)
    }
  }
  
  // Function to group messages by date
  export function groupMessagesByDate(messages:any) {
    const grouped:any = {};
  
    messages.forEach((message:any) => {
      const label = getDateLabel(message.date.seconds);
      if (!grouped[label]) {
        grouped[label] = [];
      }
      grouped[label].push(message);
    });
  
    // Convert grouped object into the desired response format
    return Object.keys(grouped).map((date) => ({
      date,
      messages: grouped[date],
    }));
  }